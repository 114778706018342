<template>
  <div class="sidebar">
    <div class="dashboard" id="dementie-information">
      <h1>Overlijden en Nazorg fase</h1>
      <p>Stervensfase en nazorg: Als het ziektepad van de persoon met dementie het einde nadert spreken wij over de Stervens- en nazorgfase. Deze fase is gericht op een zo waardig en prettig mogelijk einde van het leven, met daarin de juiste zorg en ondersteuning voor de persoon met dementie en de naasten. Hieronder kan het tijdig opschalen van professionele zorg vallen, bijvoorbeeld in de vorm van een verblijf in een verpleeghuis. Ook het welzijn van de mantelzorger tijdens en na het overlijden van de partner met dementie vormt hier een onderdeel van.
      Deze pagina wordt de komende tijd aangevuld met nieuwe inzichten. Op dit moment kunt u de volgende informatie vinden over de stervens- en nazorgfase van mensen met dementie en hun naasten:</p>
      <br>
      <li><router-link class="item" to="/Nazorg/1">Zorg en Ondersteuning</router-link></li>
      <li><router-link class="item" to="/Nazorg/9">Wmo/Wlz</router-link></li>
      <li><router-link class="item" to="/Nazorg/2">Wonen</router-link></li>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: { },
  methods: {
  }
}

</script>

<style>
.item {
  color: var(--color-1);
}
</style>
